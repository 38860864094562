import React from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import useTranslation from "../hooks/useTranslation";

const AboutUs = () => {
  const router = useRouter();
  const { t } = useTranslation();
  return (
    <div
      className="relative z-20 bg-white w-full h-max md:px-8 overflow-hidden"
      id="about"
    >
      <div className="max-w-[1366px] mx-auto relative z-[5]">
        <div className="absolute z-0 top-0 left-1/2 -translate-x-1/2">
          <img src="/svg/logo-transparent.svg" alt="logo" />
        </div>
        <div className="flex flex-col items-center pt-[70px] lg:px-0 px-8 lg:gap-10 gap-6 relative z-1 h-full">
          <span className="text-[#1F1E1F] font-black lg:text-[44px] text-[24px] leading-[62px] tracking-widest uppercase">
            {t("à propos de nous")}
          </span>
          <p className="text-[#1F1E1F] lg:max-w-[800px] font-medium text-base lg:text-center text-justify tracking-wider leading-7">
            {t(
              "FIDDARI reconnaît l'importance croissante de l'économie du travail indépendant et comprend les défis uniques auxquels sont confrontés les professionnels indépendants. Nous nous engageons à fournir un système complet qui donne aux freelances les moyens d'atteindre facilement leurs objectifs. En utilisant une technologie innovante, des processus rationalisés et un soutien adapté, nous visons à simplifier l'expérience du travail indépendant, à améliorer la productivité et à favoriser la réussite des freelances dans divers travaux."
            )}
          </p>
          <div className="lg:pt-28 pt-8 relative">
            <div className="relative lg:block hidden lg:w-[1000px] h-[433px]">
              <img
                src="/images/about.jpg"
                alt="aboutus"
                className="z-10 object-cover bg-left rounded-[28.2292px]"
              />
              <div className="absolute bg-[#974bb630] z-[1] rounded-[28.2292px] w-full h-full top-0 left-0 -translate-x-10 -translate-y-10"></div>
            </div>
            <div className="relative lg:hidden block h-[355px] w-[calc(100%+1rem)]">
              <img
                src="/images/about-mobile.jpg"
                alt="about us mobile"
                className="z-10 object-cover bg-left rounded-[28.2292px]"
              />
              <div className="absolute bg-[#974bb630] z-[1] rounded-[28.2292px] w-full h-full top-0 left-0 lg:-translate-x-10 -translate-x-5 lg:-translate-y-10 -translate-y-5"></div>
            </div>
            <div className="w-full pt-[50px] pb-[60px] lg:py-[30px] flex items-center justify-center">
              <Link
                href="/AboutUs"
                className="text-white bg-[#923FB3] rounded-[8px] p-[10px] w-[200px] text-center mt-10"
              >
                {t("Voir plus")}
              </Link>
            </div>
          </div>
        </div>
      </div>
      <img
        src="/svg/about-bg.svg"
        alt="about-back"
        className="absolute top-[40%] lg:top-0 min-w-[220%] lg:w-full lg:min-w-full right-0 z-[-1]"
      />
    </div>
  );
};

export default AboutUs;
