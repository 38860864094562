import { useRouter } from "next/router";
import React from "react";
import useTranslation from "../hooks/useTranslation";

const FindUs = () => {
  const router = useRouter();

  const { t } = useTranslation();
  return (
    <>
      {router.locale === "ar" ? (
        <div className="w-full lg:h-[728px] h-max bg-gradient1">
          <div className={`lg:grid hidden lg:grid-cols-2 grid-cols-1 h-full `}>
            <div className={`flex justify-end`}>
              <div className="flex flex-col  gap-8 pt-[156px]">
                <span className="font-semibold capitalize text-white tracking-widest text-2xl">
                  {t("réservez facilement")}
                </span>
                <span className="font-bold text-[44px] leading-[66px] tracking-widest text-white uppercase">
                  {t("trouvez nous sur")}
                </span>
                <p className="max-w-[500px] text-base tracking-wider font-semibold text-white">
                  {t(
                    "Chez FIDDARI, nous accordons la priorité aux besoins et aux aspirations des freelances. Nous comprenons que les freelances ont besoin de solutions flexibles qui répondent à leurs exigences spécifiques."
                  )}
                </p>
                <div className="flex items-center gap-4 pt-[100px]">
                  <img src="/svg/appstore.svg" alt />
                  <img src="/svg/googleplay.svg" alt />
                </div>
              </div>
            </div>
            <div className={`relative h-full flex flex-col justify-end`}>
              <div className={`absolute bottom-0 left-0  `}>
                <img src="/svg/us.svg" alt />
              </div>
            </div>
          </div>
          <div className="lg:hidden flex flex-col-reverse gap-5 h-full">
            <div className="relative h-full flex flex-col justify-end">
              <img src="/svg/us-mobile.svg" alt />
            </div>
            <div className="flex">
              <div className="flex flex-col gap-8 lg:pt-[156px] pt-[73px] px-8">
                <span className="font-semibold capitalize text-white tracking-widest text-base">
                  {t("réservez facilement")}
                </span>
                <span className="font-bold text-[22px] leading-[31px] tracking-widest text-white uppercase">
                  {t("trouvez nous sur")}
                </span>
                <p className="lg:max-w-[500px] text-base tracking-wider font-semibold text-white">
                  {t(
                    "Chez FIDDARI, nous accordons la priorité aux besoins et aux aspirations des freelances. Nous comprenons que les freelances ont besoin de solutions flexibles qui répondent à leurs exigences spécifiques."
                  )}
                </p>
                <div className="flex flex-col gap-4 pt-[20px] items-start">
                  <img
                    src="/svg/appstore-mobile.svg"
                    alt
                    className="h-[50px]"
                  />
                  <img
                    src="/svg/googleplay-mobile.svg"
                    alt
                    className="h-[50px]"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full lg:h-[728px] h-max bg-gradient1  ">
          <div className={`lg:grid hidden lg:grid-cols-2 grid-cols-1 h-full  `}>
            <div className={`relative h-full flex flex-col justify-end`}>
              <div className={`absolute bottom-0 left-0  `}>
                <img src="/svg/us.svg" alt />
              </div>
            </div>
            <div className={`flex  `}>
              <div className="flex flex-col gap-8 pt-[156px]">
                <span className="font-semibold capitalize text-white tracking-widest text-2xl">
                  {t("réservez facilement")}
                </span>
                <span className="font-bold text-[44px] leading-[66px] tracking-widest text-white uppercase">
                  {t("trouvez nous sur")}
                </span>
                <p className="max-w-[500px] text-base tracking-wider font-semibold text-white">
                  {t(
                    "Chez FIDDARI, nous accordons la priorité aux besoins et aux aspirations des freelances. Nous comprenons que les freelances ont besoin de solutions flexibles qui répondent à leurs exigences spécifiques."
                  )}
                </p>
                <div className="flex items-center gap-4 pt-[100px]">
                  <img src="/svg/appstore.svg" alt />
                  <img src="/svg/googleplay.svg" alt />
                </div>
              </div>
            </div>
          </div>
          <div className="lg:hidden flex flex-col-reverse gap-5 h-full">
            <div className="relative h-full flex flex-col justify-end">
              <img src="/svg/us-mobile.svg" alt />
            </div>
            <div className="flex">
              <div className="flex flex-col gap-8 lg:pt-[156px] pt-[73px] px-8">
                <span className="font-semibold capitalize text-white tracking-widest text-base">
                  {t("réservez facilement")}
                </span>
                <span className="font-bold text-[22px] leading-[31px] tracking-widest text-white uppercase">
                  {t("trouvez nous sur")}
                </span>
                <p className="lg:max-w-[500px] text-base tracking-wider font-semibold text-white">
                  {t(
                    "Chez FIDDARI, nous accordons la priorité aux besoins et aux aspirations des freelances. Nous comprenons que les freelances ont besoin de solutions flexibles qui répondent à leurs exigences spécifiques."
                  )}
                </p>
                <div className="flex flex-col gap-4 pt-[20px] items-start">
                  <img
                    src="/svg/appstore-mobile.svg"
                    alt
                    className="h-[50px]"
                  />
                  <img
                    src="/svg/googleplay-mobile.svg"
                    alt
                    className="h-[50px]"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FindUs;
