import React from "react";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import useTranslation from "../hooks/useTranslation";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";

const Footer = () => {
  const router = useRouter();
  const [isActive, setIsActive] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    setIsActive(router?.pathname?.split("/")[1]?.trim());
  }, [router]);

  return (
    <div className="bg-[#241C28] md:px-8">
      <div className="max-w-[1366px] mx-auto">
        <div className="lg:py-[150px] py-[80px]">
          <div className="flex lg:flex-row flex-col flex-wrap lg:justify-between lg:items-start items-center justify-center gap-20">
            <div className="lg:block hidden">
              <Image
                src="/svg/logo-white.svg"
                alt="FiDarri logo"
                width={273}
                height={153}
              />
            </div>
            <div className="flex flex-col gap-10 text-white lg:items-start items-center">
              <h4 className="font-bold text-lg tracking-wider capitalize">
                FiDarri
              </h4>
              <div className="flex flex-col gap-5 tracking-wider text-base leading-5 lg:items-start items-center">
                <Link
                  href="/ConditionsGenerale"
                  className="ha:hover:text-secondary"
                >
                  {t("termes et conditions")}
                </Link>
                <Link href="/Politique" className="ha:hover:text-secondary">
                  {t("politique de confidentialité")}
                </Link>
                {/* <Link href="/" className="ha:hover:text-secondary">
                  Plateforme responsable
                </Link> */}
              </div>
            </div>
            <div className="flex flex-col gap-10 text-white lg:items-start items-center">
                <h4 className="font-bold text-lg tracking-wider capitalize">
                  {t("à propos")}
                </h4>
              <div className="flex flex-col gap-5 tracking-wider text-base leading-5 lg:items-start items-center">
                {/* <Link href="/" className="ha:hover:text-secondary">
                  Blog
                </Link> */}
                <Link href="/codePromo" className="ha:hover:text-secondary">
                  {t("Code promos")}
                </Link>
                <Link href="/AboutUs" className="ha:hover:text-secondary">
                  {t("à propos de nous")}
                </Link>
              </div>
            </div>
            <div className="flex flex-col gap-10 text-white lg:items-start items-center">
              <h4 className="font-bold text-lg tracking-wider capitalize">
                {t("contact")}
              </h4>
              <div className="flex flex-col gap-5 tracking-wider text-base leading-5 lg:items-start items-center">
                <Link
                  href={
                    isActive === "recrutement" ? "/inscription" : "/register"
                  }
                  className="ha:hover:text-secondary"
                >
                  {t("Rejoignez-nous")}
                </Link>
                <Link href="tel:0773456096" className="ha:hover:text-secondary">
                  +213 (0) 773 45 60 96
                </Link>
                <Link
                  href="mailto:contact@fidarri.com"
                  className="ha:hover:text-secondary"
                >
                  contact@fidarri.com
                </Link>
              </div>
            </div>
            <div className="flex flex-col gap-10 text-white lg:items-start items-center">
              <h4 className="font-bold text-lg tracking-wider capitalize">
                {t("Besoin d'aide ?")}
              </h4>
              <div className="flex flex-col gap-5 tracking-wider text-base leading-5 lg:items-start items-center">
                <Link href="/help" className="ha:hover:text-secondary">
                  {t("Centre d’aide")}
                </Link>
                {/* <Link href="/" className="ha:hover:text-secondary">
                  Se plaindre
                </Link> */}
              </div>
            </div>{" "}
            <div className="lg:hidden block">
              <Image
                src="/svg/logo-white.svg"
                alt="FiDarri Logo"
                width={273}
                height={153}
              />
            </div>
          </div>
        </div>
        <div className="flex justify-center lg:justify-end border-b border-[#C8C8C8]">
          <div className="flex items-center gap-4 py-6 ">
            <img src="/svg/facebook.svg" alt="facebook" />
            <img src="/svg/twitter.svg" alt="twitter" />
            <img src="/svg/insta.svg" alt="insta" />
            <img src="/svg/in.svg" alt="in" />
          </div>
        </div>
        <div className="py-[46px] w-full ">
          <div className="flex w-full font-semibold justify-center items-center text-center  text-xs text-white tracking-widest">
            <p className="max-w-[335px]">
              Designed and developed by Smartinia.dz all Rights Reserved to ©
              FiDarri 2023
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
