import { useRouter } from "next/router";
import React from "react";

import { BackgroundImage } from "react-image-and-background-image-fade";
import useTranslation from "../hooks/useTranslation";

const JoinUs = () => {
  const router = useRouter();
  const { t } = useTranslation();
  return (
    <>
      <div className="lg:block hidden ">
        <BackgroundImage
          useChild
          src="/images/join-us.jpg"
          alt
          width="100%"
          height={"595px"}
          style={{}}
        >
          <div
            style={{
              backgroundAttachment: "fixed",
              backgroundRepeat: "no-repeat",
            }}
            className="w-full h-full"
          >
            <div className="lg:py-[127px] py-[70px] flex justify-center h-full lg:px-0 px-8">
              <div className="flex flex-col gap-7 items-center max-w-xl text-center">
                <span className="font-bold lg:text-[38px] text-[24px] tracking-[0.1em] text-white leading-[53px] uppercase">
                  {t("vous voulez travailler professionnellement ?")}
                </span>
                <p className="text-base font-medium tracking-widest text-white">
                  {t(
                    "Développez votre clientèle ! nous saurons vous accompagner."
                  )}
                </p>
                <button
                  onClick={() => router.push(`/${router.locale}/inscription`)}
                  className="mt-[65px] bg-white rounded-lg text-secondary text-base leading-5 font-medium duration-300 px-[43px] py-[12.5px] ha:hover:bg-secondary ha:hover:text-white h-max"
                >
                  {t("Rejoignez-nous")}
                </button>
              </div>
            </div>
          </div>
        </BackgroundImage>
      </div>
      <div className="lg:hidden block">
        <div
          style={{
            backgroundImage: "url(/images/join-us.jpg)",
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
          className="w-full h-max"
        >
          <div className="lg:py-[127px] py-[50px] flex justify-center h-full lg:px-0 px-8">
            <div className="flex flex-col gap-7 items-center max-w-xl text-center">
              <span className="font-bold lg:text-[38px] text-[24px] tracking-[0.1em] text-white leading-[53px] uppercase">
                {t("vous voulez travailler professionnellement ?")}
              </span>
              <p className="text-base font-medium tracking-widest text-white">
                {t(
                  "Développez votre clientèle ! nous saurons vous accompagner."
                )}
              </p>
              <button
                onClick={() => router.push(`/${router.locale}/inscription`)}
                className="mt-[65px] bg-white rounded-lg text-secondary text-base leading-5 font-medium duration-300 px-[43px] py-[12.5px] ha:hover:bg-secondary ha:hover:text-white h-max"
              >
                {t("Rejoignez-nous")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JoinUs;
